@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
  background-color: #beedf9;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/* custom CSS */
body {
  background-color: #3498db;
}

.form-img {
  position: relative;
  display: block;
  margin: -3vw auto 0;
  width: 55vw;
}

form {
  background-color: #122b53;
  border-radius: 30px;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  margin: -23vw auto 0;
  padding: 2vw;
  position: relative;
  width: 28vw;
  z-index: 99;
}

form label {
  display: block;
  font-size: 1.04vw;
}

form input {
  border-radius: 20px;
  color: #000;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  height: 1.8vw;
  
  padding-left: .5vw;
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.error_txt {
  color: red !important;
  
} 
.mb {
  margin-bottom: .75vw;
  
}

form p {
  font-size: 1.04vw;
  font-weight: 500;
  margin-top: 3vw;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

form span {
  font-size: .83vw;
  font-weight: 100;
  margin-top: 0;
  text-align: center;
}

.button {
  border-radius: 20px;
  height: 1.8vw;
  width: 100%;
  font-size: 13.33px;
  margin-bottom: 9px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.button2 {
  background-color: #fff;
  border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-size: .73vw;
  font-weight: 500;
  margin-bottom: 2vw;
  padding: .3vw 0;
  line-height: 1;
}

.submitBtn {
  font-size: 11px !important;
  font-family: Montserrat, sans-serif;
  border-radius: 20px;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    height: 1.8vw;
    margin-bottom: .75vw;
    padding-left: .5vw;
    width: 100%;
    text-align: center;
}

footer img {
  display: block;
  margin: 0 auto;
  width: 32.5vw;    margin-top: 3.2vw;
}

footer p {
  color: #450e0e;
    font-family: Bebas Neue, sans-serif;
    font-size: 1.04vw;
    margin: 0 auto;
    text-align: center;
}

.input_active button.active {
  background-color: #beedf9;
  color: #122b53;
}

.inactive {
  background-color: white;
  color: black;
}

.header-img {
  display: block;
  margin: 0 auto;
  width: 55vw;
}

.p {
  font-size: .83vw;
  font-weight: 100;
  margin-top: 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.footer-img {
  margin-bottom: 1vw !important ;
  margin-top: 1vw !important;
}

/* media-query */
@media screen and (max-width: 468px) {
  .header-img {
    width: 100vw;
  }
}

@media screen and (max-width: 468px) {
  .form-img {
    width: 100vw;
  }
}

@media screen and (max-width: 468px) {
  form {
    margin-top: -42vw;
    padding-bottom: 3vw;
    padding-top: 3vw;
    width: 50vw;
  }
}

@media screen and (max-width: 468px) {
  form label {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 468px) {
  form input {
    height: 4.8vw;
  }
}

@media screen and (max-width: 468px) {
  form p {
    font-size: 2.74vw;
    margin-top: 7vw;
  }
}

@media screen and (max-width: 468px) {
  .p {
    font-size: 2.3vw;
  }
}

@media screen and (max-width: 468px) {
  .input_active .button {
    font-size: 2.5vw;
    height: 4.8vw;
  }
}

@media screen and (max-width: 468px) {
  .button2 {
    font-size: 2vw !important;
    line-height: 1;
  }
}

@media screen and (max-width: 468px) {
  .submitBtn {
    height: 4.8vw;
    font-size: 9px !important;
  }
}

@media screen and (max-width: 468px) {
  footer img {
    width: 58.5vw;
  }
}

@media screen and (max-width: 468px) {
 footer .footer-img {
      width: 63vw;
  }
}

@media screen and (max-width: 468px) {
 footer p {
      font-size: 3.04vw;
  }
}

@media screen and (max-width: 468px) {
  .App {
    padding: 0;
  }
}